import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Menu from '../../components/navigation';
import ProjectsText from '../../components/projects/text';
import ProjecsGallery from '../../components/projects/gallery';
import ProjectItem from '../../components/projects/item';
import Image from '../../components/projects/image';

import logo from '../../images/projects/vj/logo.svg';
import text1 from '../../images/projects/vj/text.svg';
import diamant from '../../images/projects/vj/diamant.svg';

import img1 from '../../images/projects/vj/img1.jpg';
import img2 from '../../images/projects/vj/img2.jpg';
import img3 from '../../images/projects/vj/img3.jpg';
import img4 from '../../images/projects/vj/img4.jpg';
import img5 from '../../images/projects/vj/img5.jpg';
import img6 from '../../images/projects/vj/img6.jpg';
import img7 from '../../images/projects/vj/img7.jpg';

const title = 'Hochzeit Viktoria & Jens';
const text =
	'Mit dem Entwurf der Einladungskarten für meine Hochzeit ist ein ganzheitliches Gestaltungskonzept entstanden. Aus den Initialen entwickelte sich ein Logo. Es wurden verschiedenste Medien durchgeplant: Einladungskarten, Sitzplatzkarten, Gastgeschenke, das Gästebuch, das Design der Hochzeitstorte und sogar die Gravur der Ringe. Sowas macht einfach Spaß!';

/**
 * Page
 */
const Page = () => {
	return (
		<Layout>
			<SEO title="" />
			<Menu selected="projekte" />
			<ProjectsText text={text} title={title} />
			<div className="page-width align-center"></div>
			<ProjecsGallery>
				<ProjectItem>
					<img src={logo} alt="" className="vj-logo" />
				</ProjectItem>
				<ProjectItem>
					<img src={text1} alt="" className="vj-text" />
				</ProjectItem>
				<ProjectItem>
					<Image src={img1} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img2} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img3} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img4} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img5} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img6} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img7} />
				</ProjectItem>
				<ProjectItem>
					<img src={diamant} alt="" className="vj-diamant" />
				</ProjectItem>
			</ProjecsGallery>
		</Layout>
	);
};

export default Page;
